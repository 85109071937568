import axios from 'axios'
import $ from 'jquery'
import Vue from 'vue/dist/vue.esm'
import Expanding from 'expanding-textareas'
import Cookies from 'cookies-js'
import vex from 'vex-js'
import ready from 'document-ready'

vex.defaultOptions.className = 'vex-theme-plain'

ready(() => {
  if (!document.getElementById('vue-wishlist')) { return }

  let csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content')
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  axios.defaults.headers.common['Accept'] = 'application/json'

  let wishlistVmState = {
    wishlist: __WISHLIST__,
    unit: Cookies.get('braiform.unit') || 'cms',
    locale: locale()
  }

  Vue.component('wishlist-product', {
    props: ['product'],
    methods: {
      remove: function () {
        axios.delete('/my-catalogue?group=' + this.product.group)
          .then((response) => {
            wishlistVm.$data.wishlist = response.data.wishlist
            window.updateWishlistCount(response.data.wishlist)
          }).catch((error) => {
            alert(error);
          })
      }
    },
    computed: {
      localizedFirstFeature: function () {
        let locale = wishlistVmState.locale
        let localeFeatures = this.product.features.filter(feature => {
          return feature.locale === null || feature.locale == locale
        })
        if (localeFeatures.length) {
          return localeFeatures[0]['description']
        } else {
          return null;
        }
      },
      unitCm: function () {
        return wishlistVmState.unit === 'cms'
      },
      unitInches: function () {
        return wishlistVmState.unit === 'inches'
      },
      sizesCms: function () {
        return this.product.sizes_cm.sort().map(size => `${size}cm`).join(', ')
      },
      sizesInch: function () {
        return this.product.sizes_inch_cache.sort().map(size => `${size}”`).join(', ')
      }
    },
    template: `<div class="bc-wishlist-product">
      <div class="bc-wishlist-product__image"><img :src="product.images[0]" width="500"></div>
      <div class="bc-wishlist-product__info">
        <span class="bc-wishlist-product__group">{{ product.group }}</span>
        <button class="bc-wishlist-product__button" type="button" @click="remove">
          <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="M9 16.122c3.823 0 6.922-3.1 6.922-6.922 0-3.823-3.1-6.922-6.922-6.922-3.823 0-6.922 3.1-6.922 6.922 0 3.823 3.1 6.922 6.922 6.922zm0 1.54C4.327 17.66.54 13.87.54 9.2.54 4.527 4.326.74 9 .74c4.673 0 8.46 3.787 8.46 8.46 0 4.673-3.787 8.46-8.46 8.46zM6.69 8.43c-.418 0-.767.343-.767.768 0 .428.344.77.767.77h4.62c.418 0 .767-.345.767-.77 0-.428-.344-.77-.767-.77H6.69z" fill-rule="nonzero" fill="#D65466"/></svg>
          Remove
        </button>
        <span class="bc-wishlist-product__feature">{{ product.description }}</span>
        <span class="bc-wishlist-product__sizes" v-if="unitCm" data-sizes="cms">{{ product.sizes_cm }}</span>
        <span class="bc-wishlist-product__sizes" v-if="unitInches" data-sizes="inches">{{ product.sizes_inch }}</span>
      </div>
    </div>`
  })

  window.wishlistVm = new Vue({
    el: '#vue-wishlist',
    data: wishlistVmState,
    watch: {
      'unit': function (newUnit) {
        Cookies.set('braiform.unit', newUnit)
      }
    },
    methods: {
      removeAll: function () {
        axios.delete('/my-catalogue?all')
        .then((response) => {
          this.wishlist = response.data.wishlist
          window.updateWishlistCount(response.data.wishlist)
        }).catch((error) => {
          alert(error);
        })
      }
    },
    mounted: function () {
      if (!this.itemCount) return
      new Expanding(this.$el.querySelector('textarea'))
    },
    computed: {
      itemCount: function () {
        return this.wishlist.products.length
      },
      itemsCountF: function () {
        let count = this.itemCount
        if (count == 1) return `1 Item`
        return `${count} Items`
      }
    }
  })

  $(document).on('click', '.bc-catalogue-download', function (e) {
    e.preventDefault()
    let v1 = vex.open({
      unsafeContent: `
        <div class="vex-header">Download Catalogue</div>
        <div class="vex-main">
          <label for="wish_list_download_title" class="bc-form-label">Title Your Catalogue</label>
          <div class="bc-form-field">
            <input placeholder="My Catalogue" type="text" id="wish_list_download_title" maxlength="140">
          </div>
          <span class="bc-button vex-download-button">Download</span>
        </div>
      `,
      afterOpen: function () {
        let _this = this;
        $('.vex-download-button').one('click', function (e) {
          e.preventDefault()
          let title = $(_this.contentEl).find('input').val()
          let url = '/my-catalogue.pdf'
          url += `?locale=${wishlistVmState.locale}`
          if (title !== '') {
            Cookies.set('braiform.catalogueTitle', title)
            url += `&title=${encodeURIComponent(title)}`
          }
          window.open(url)
          _this.close()
        })
      }
    })
  })
})
