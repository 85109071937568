/* eslint no-console:0 */

import 'babel-polyfill'
import $ from 'jquery'
import axios from 'axios'
import vex from 'vex-js'
import Cookies from 'cookies-js'
import './product'
import './products'
import './related-products'
import './wishlist'

vex.defaultOptions.className = 'vex-theme-plain'

let csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content')
axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
axios.defaults.headers.common['Accept'] = 'application/json'

window.updateWishlistCount = function (wishlist) {
  let count = wishlist.products.length
  $('[data-wish-list-count]').text(count).attr('data-count', count)
}

window.locale = function () {
  let localeCookie = Cookies.get('braiform.locale')
  if (localeCookie) {
    return localeCookie
  }
  let lang = window.navigator.userLanguage || window.navigator.language
  if (lang == 'en-GB' || lang == 'en-US') {
    return lang
  } else {
    return 'en-GB'
  }
}

$(document).on('click', '[data-newsletter-signup]', function (e) {
  e.preventDefault()
  let vexInstance = vex.open({
    unsafeContent: `
      <div class="vex-header">Sign-up for Updates</div>
      <div class="vex-main">
        <p>Receive the latest news and updates from Tam Hangers, straight to your inbox.</p>
        <form>
          <label class="bc-form-label">Full Name</label>
          <div class="bc-form-field">
            <input type="text" name="full_name" required>
          </div>
          <label class="bc-form-label">Email Address</label>
          <div class="bc-form-field">
            <input type="text" name="email_address" required>
          </div>
          <label class="bc-form-label">Location</label>
          <div class="bc-form-field">
            <select name="location">
              <option disabled selected>Please Select</option>
              <option value="AF">Afghanistan</option>
              <option value="AX">Åland Islands</option>
              <option value="AL">Albania</option>
              <option value="DZ">Algeria</option>
              <option value="AS">American Samoa</option>
              <option value="AD">Andorra</option>
              <option value="AO">Angola</option>
              <option value="AI">Anguilla</option>
              <option value="AQ">Antarctica</option>
              <option value="AG">Antigua and Barbuda</option>
              <option value="AR">Argentina</option>
              <option value="AM">Armenia</option>
              <option value="AW">Aruba</option>
              <option value="AU">Australia</option>
              <option value="AT">Austria</option>
              <option value="AZ">Azerbaijan</option>
              <option value="BS">Bahamas</option>
              <option value="BH">Bahrain</option>
              <option value="BD">Bangladesh</option>
              <option value="BB">Barbados</option>
              <option value="BY">Belarus</option>
              <option value="BE">Belgium</option>
              <option value="BZ">Belize</option>
              <option value="BJ">Benin</option>
              <option value="BM">Bermuda</option>
              <option value="BT">Bhutan</option>
              <option value="BO">Bolivia, Plurinational State of</option>
              <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
              <option value="BA">Bosnia and Herzegovina</option>
              <option value="BW">Botswana</option>
              <option value="BV">Bouvet Island</option>
              <option value="BR">Brazil</option>
              <option value="IO">British Indian Ocean Territory</option>
              <option value="BN">Brunei Darussalam</option>
              <option value="BG">Bulgaria</option>
              <option value="BF">Burkina Faso</option>
              <option value="BI">Burundi</option>
              <option value="KH">Cambodia</option>
              <option value="CM">Cameroon</option>
              <option value="CA">Canada</option>
              <option value="CV">Cape Verde</option>
              <option value="KY">Cayman Islands</option>
              <option value="CF">Central African Republic</option>
              <option value="TD">Chad</option>
              <option value="CL">Chile</option>
              <option value="CN">China</option>
              <option value="CX">Christmas Island</option>
              <option value="CC">Cocos (Keeling) Islands</option>
              <option value="CO">Colombia</option>
              <option value="KM">Comoros</option>
              <option value="CG">Congo</option>
              <option value="CD">Congo, The Democratic Republic of the</option>
              <option value="CK">Cook Islands</option>
              <option value="CR">Costa Rica</option>
              <option value="CI">Côte d&#39;Ivoire</option>
              <option value="HR">Croatia</option>
              <option value="CU">Cuba</option>
              <option value="CW">Curaçao</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="DK">Denmark</option>
              <option value="DJ">Djibouti</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominican Republic</option>
              <option value="EC">Ecuador</option>
              <option value="EG">Egypt</option>
              <option value="SV">El Salvador</option>
              <option value="GQ">Equatorial Guinea</option>
              <option value="ER">Eritrea</option>
              <option value="EE">Estonia</option>
              <option value="ET">Ethiopia</option>
              <option value="FK">Falkland Islands (Malvinas)</option>
              <option value="FO">Faroe Islands</option>
              <option value="FJ">Fiji</option>
              <option value="FI">Finland</option>
              <option value="FR">France</option>
              <option value="GF">French Guiana</option>
              <option value="PF">French Polynesia</option>
              <option value="TF">French Southern Territories</option>
              <option value="GA">Gabon</option>
              <option value="GM">Gambia</option>
              <option value="GE">Georgia</option>
              <option value="DE">Germany</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GR">Greece</option>
              <option value="GL">Greenland</option>
              <option value="GD">Grenada</option>
              <option value="GP">Guadeloupe</option>
              <option value="GU">Guam</option>
              <option value="GT">Guatemala</option>
              <option value="GG">Guernsey</option>
              <option value="GN">Guinea</option>
              <option value="GW">Guinea-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HT">Haiti</option>
              <option value="HM">Heard Island and McDonald Islands</option>
              <option value="VA">Holy See (Vatican City State)</option>
              <option value="HN">Honduras</option>
              <option value="HK">Hong Kong</option>
              <option value="HU">Hungary</option>
              <option value="IS">Iceland</option>
              <option value="IN">India</option>
              <option value="ID">Indonesia</option>
              <option value="IR">Iran, Islamic Republic of</option>
              <option value="IQ">Iraq</option>
              <option value="IE">Ireland</option>
              <option value="IM">Isle of Man</option>
              <option value="IL">Israel</option>
              <option value="IT">Italy</option>
              <option value="JM">Jamaica</option>
              <option value="JP">Japan</option>
              <option value="JE">Jersey</option>
              <option value="JO">Jordan</option>
              <option value="KZ">Kazakhstan</option>
              <option value="KE">Kenya</option>
              <option value="KI">Kiribati</option>
              <option value="KP">Korea, Democratic People&#39;s Republic of</option>
              <option value="KR">Korea, Republic of</option>
              <option value="KW">Kuwait</option>
              <option value="KG">Kyrgyzstan</option>
              <option value="LA">Lao People&#39;s Democratic Republic</option>
              <option value="LV">Latvia</option>
              <option value="LB">Lebanon</option>
              <option value="LS">Lesotho</option>
              <option value="LR">Liberia</option>
              <option value="LY">Libya</option>
              <option value="LI">Liechtenstein</option>
              <option value="LT">Lithuania</option>
              <option value="LU">Luxembourg</option>
              <option value="MO">Macao</option>
              <option value="MK">Macedonia, Republic of</option>
              <option value="MG">Madagascar</option>
              <option value="MW">Malawi</option>
              <option value="MY">Malaysia</option>
              <option value="MV">Maldives</option>
              <option value="ML">Mali</option>
              <option value="MT">Malta</option>
              <option value="MH">Marshall Islands</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritania</option>
              <option value="MU">Mauritius</option>
              <option value="YT">Mayotte</option>
              <option value="MX">Mexico</option>
              <option value="FM">Micronesia, Federated States of</option>
              <option value="MD">Moldova, Republic of</option>
              <option value="MC">Monaco</option>
              <option value="MN">Mongolia</option>
              <option value="ME">Montenegro</option>
              <option value="MS">Montserrat</option>
              <option value="MA">Morocco</option>
              <option value="MZ">Mozambique</option>
              <option value="MM">Myanmar</option>
              <option value="NA">Namibia</option>
              <option value="NR">Nauru</option>
              <option value="NP">Nepal</option>
              <option value="NL">Netherlands</option>
              <option value="NC">New Caledonia</option>
              <option value="NZ">New Zealand</option>
              <option value="NI">Nicaragua</option>
              <option value="NE">Niger</option>
              <option value="NG">Nigeria</option>
              <option value="NU">Niue</option>
              <option value="NF">Norfolk Island</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="NO">Norway</option>
              <option value="OM">Oman</option>
              <option value="PK">Pakistan</option>
              <option value="PW">Palau</option>
              <option value="PS">Palestine, State of</option>
              <option value="PA">Panama</option>
              <option value="PG">Papua New Guinea</option>
              <option value="PY">Paraguay</option>
              <option value="PE">Peru</option>
              <option value="PH">Philippines</option>
              <option value="PN">Pitcairn</option>
              <option value="PL">Poland</option>
              <option value="PT">Portugal</option>
              <option value="PR">Puerto Rico</option>
              <option value="QA">Qatar</option>
              <option value="RE">Réunion</option>
              <option value="RO">Romania</option>
              <option value="RU">Russian Federation</option>
              <option value="RW">Rwanda</option>
              <option value="BL">Saint Barthélemy</option>
              <option value="SH">Saint Helena, Ascension and Tristan da Cunha</option>
              <option value="KN">Saint Kitts and Nevis</option>
              <option value="LC">Saint Lucia</option>
              <option value="MF">Saint Martin (French part)</option>
              <option value="PM">Saint Pierre and Miquelon</option>
              <option value="VC">Saint Vincent and the Grenadines</option>
              <option value="WS">Samoa</option>
              <option value="SM">San Marino</option>
              <option value="ST">Sao Tome and Principe</option>
              <option value="SA">Saudi Arabia</option>
              <option value="SN">Senegal</option>
              <option value="RS">Serbia</option>
              <option value="SC">Seychelles</option>
              <option value="SL">Sierra Leone</option>
              <option value="SG">Singapore</option>
              <option value="SX">Sint Maarten (Dutch part)</option>
              <option value="SK">Slovakia</option>
              <option value="SI">Slovenia</option>
              <option value="SB">Solomon Islands</option>
              <option value="SO">Somalia</option>
              <option value="ZA">South Africa</option>
              <option value="GS">South Georgia and the South Sandwich Islands</option>
              <option value="SS">South Sudan</option>
              <option value="ES">Spain</option>
              <option value="LK">Sri Lanka</option>
              <option value="SD">Sudan</option>
              <option value="SR">Suriname</option>
              <option value="SJ">Svalbard and Jan Mayen</option>
              <option value="SZ">Swaziland</option>
              <option value="SE">Sweden</option>
              <option value="CH">Switzerland</option>
              <option value="SY">Syrian Arab Republic</option>
              <option value="TW">Taiwan</option>
              <option value="TJ">Tajikistan</option>
              <option value="TZ">Tanzania, United Republic of</option>
              <option value="TH">Thailand</option>
              <option value="TL">Timor-Leste</option>
              <option value="TG">Togo</option>
              <option value="TK">Tokelau</option>
              <option value="TO">Tonga</option>
              <option value="TT">Trinidad and Tobago</option>
              <option value="TN">Tunisia</option>
              <option value="TR">Turkey</option>
              <option value="TM">Turkmenistan</option>
              <option value="TC">Turks and Caicos Islands</option>
              <option value="TV">Tuvalu</option>
              <option value="UG">Uganda</option>
              <option value="UA">Ukraine</option>
              <option value="AE">United Arab Emirates</option>
              <option value="GB">United Kingdom</option>
              <option value="UM">United States Minor Outlying Islands</option>
              <option value="US">United States</option>
              <option value="UY">Uruguay</option>
              <option value="UZ">Uzbekistan</option>
              <option value="VU">Vanuatu</option>
              <option value="VE">Venezuela, Bolivarian Republic of</option>
              <option value="VN">Viet Nam</option>
              <option value="VG">Virgin Islands, British</option>
              <option value="VI">Virgin Islands, U.S.</option>
              <option value="WF">Wallis and Futuna</option>
              <option value="EH">Western Sahara</option>
              <option value="YE">Yemen</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </select>
          </div>
          <input type="text" name="zip_code"  value="" tabindex="-1" autocomplete="off" style="display:none !important">
        </form>
        <span class="bc-button">Submit</span>
      </div>
    `,
    afterOpen: function () {
      let _this = this
      $(this.contentEl).on('click', '.bc-button', function () {
        $(this).text('Submitting…').prop('disabled', true)
        let $form = $(_this.contentEl).find('form')
        axios.post('/newsletter', {
          newsletter: {
            full_name: $form.find('[name="full_name"]').val(),
            email_address: $form.find('[name="email_address"]').val(),
            location: $form.find('[name="location"]').val(),
            zip_code: $form.find('[name="zip_code"]').val()
          }
        }).then((response) => {
          // Cookies.set('newsletterSignUp', true)
          _this.close()
          vex.open({
            unsafeContent: `
              <div class="vex-header">Thank you</div>
              <div class="vex-main">
                <p>Thank you for subscribing.</p>
              </div>`
          })
        })
      })
    }
  })
})

$(function () {
  // if (/catalogue/.test(window.location.pathname) && Cookies.get('catalogueOnboard') === undefined) {
  if (false) {
    let vexInstance = vex.open({
      unsafeContent: `
        <div class="vex-header">Using the Catalogue</div>
        <div class="vex-main">
          <ol>
            <li>Use the catalogue to browse our range of styles</li>
            <li>Choose your products and add them your catalogue</li>
            <li>Submit your catalogue and download it for reference. Our team will get back to you with a quote</li>
          </ol>
          <span class="bc-button">Continue</span>
        </div>
      `,
      afterClose: function () {
        Cookies.set('catalogueOnboard', true)
      },
      afterOpen: function () {
        var _this = this;
        $(this.contentEl).on('click', '.bc-button', function () {
          _this.close();
        })
      }
    })
  }
})

$.fn.extend({
  toggleText: function(a, b){
    return this.text(this.text() == b ? a : b)
  }
})

$(document).on('click', '.bc-departments__department__expand', function () {
  let $this = $(this)
  $this.prev().toggleClass('is-expanded')
  $this.toggleText('See all', 'See less')
})

$(document).on('click', '.bc-nav__search-toggle', function () {
  $('.bc-nav__search').addClass('is-visible')
  $('.bc-nav__search').find('input').focus()
})

$(document).on('click', '.bc-search__close', function () {
  $('.bc-nav__search').removeClass('is-visible')
})

$(document).on('mouseover', '.bc-product__image--thumb', function () {
  let $thumbImg = $(this).children('img')
  let $full = $('.bc-product__images--full')
  $full.children('img').remove()
  $thumbImg.clone().appendTo($full)
})

$(document).on('click', '[data-fancybox]', function () {
  gtag('event', 'play', {
    'event_category': 'Video',
    'event_label': 'Tam Hangers Way Video'
  })
})

$(document).on('click', '[href="/contact"]', function () {
  gtag('event', 'click', {
    'event_category': 'Generate Lead',
    'event_label': 'Contact Form'
  })
})

$(document).one('change', '#enquiry-form :input', function () {
  gtag('event', 'change', {
    'event_category': 'Generate Lead',
    'event_label': 'Contact Form'
  })
})
