import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import axios from 'axios'
import Cookies from 'cookies-js'
import ready from 'document-ready'

ready(() => {
  if (!document.getElementById('vue-product')) { return }

  let csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content')
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  axios.defaults.headers.common['Accept'] = 'application/json'

  window.productsVmState = {
    product: __PRODUCT__,
    wishlist: __WISHLIST__,
    added: false,
    unit: Cookies.get('braiform.unit') || 'cms',
    locale: locale()
  }

  window.productsVm = new Vue({
    el: '#vue-product',
    data: productsVmState,
    watch: {
      unit: function (val, oldVal) {
        Cookies.set('braiform.unit', val)
        // TODO: There is probably a nicer way to share this
        if (typeof relatedProductsVmState !== 'undefined') {
          relatedProductsVmState.unit = val
        }
      }
    },
    methods: {
      toggleInWishlist: function () {
        let action = `/my-catalogue?group=${this.product.group}`

        if (this.inWishlist) {
          axios.delete(action)
          .then((response) => {
            this.added = false
            this.wishlist = response.data.wishlist
            window.updateWishlistCount(response.data.wishlist)
          }).catch(function (error) {
            alert(error)
          })
        } else {
          axios.patch(action)
          .then((response) => {
            this.added = true
            if (this.addedTimeout) { clearTimeout(this.addedTimeout) }
            this.addedTimeout = setTimeout(() => {
              this.added = false
            }, 1000)
            this.wishlist = response.data.wishlist
            window.updateWishlistCount(response.data.wishlist)
          }).catch(function (error) {
            alert(error)
          })
        }
      },
      visit: function () {
        window.location = this.url
      }
    },
    computed: {
      unitCm: function () {
        return productsVmState.unit === 'cms'
      },
      unitInches: function () {
        return productsVmState.unit === 'inches'
      },
      localizedGarmentTypes: function () {
        let locale = productsVmState.locale
        return this.product.garment_types_cache.filter(garment_type => {
          return garment_type.locale === null || garment_type.locale == locale
        }).map(garment_type => garment_type.garment)
      },
      localizedFeatures: function () {
        let locale = productsVmState.locale
        return this.product.features.filter(feature => {
          return feature.locale === null || feature.locale == locale
        }).map(feature => feature.description)
      },
      inWishlist: function () {
        return this.wishlist.products.findIndex(p => p.id == this.product.id) !== -1
      },
      buttonClass: function () {
        if (this.added) {
          return '-added'
        } else if (this.inWishlist) {
          return '-remove'
        } else {
          return ''
        }
      },
      buttonText: function () {
        if (this.added) {
          return 'Added to my catalogue'
        } else if (this.inWishlist) {
          return 'Remove from my catalogue'
        } else {
          return 'Add to my catalogue'
        }
      },
      unitCm: function () {
        return this.unit === 'cms'
      },
      unitInches: function () {
        return this.unit === 'inches'
      },
      url: function () {
        return `/catalogue/${this.product.group}`
      }
    },
  })
})
