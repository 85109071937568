import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import axios from 'axios'
import Cookies from 'cookies-js'
import ready from 'document-ready'

ready(() => {
  if (!document.getElementById('vue-related-products')) { return }

  let csrfToken = document.getElementsByName('csrf-token')[0].getAttribute('content')
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken
  axios.defaults.headers.common['Accept'] = 'application/json'

  window.relatedProductsVmState = {
    products: __RELATED_PRODUCTS__,
    wishlist: __WISHLIST__,
    unit: Cookies.get('braiform.unit') || 'cms',
    locale: locale()
  }

  Vue.component('related-product', {
    props: ['product'],
    data: function () {
      return {
        added: false,
        addedTimeout: null
      }
    },
    mounted: function () {
      imgix.init()
      lazySizes.init()
    },
    methods: {
      toggleInWishlist: function () {
        let action = `/my-catalogue?group=${this.product.group}`

        if (this.inWishlist) {
          axios.delete(action)
          .then((response) => {
            this.added = false
            relatedProductsVmState.wishlist = response.data.wishlist
          }).catch(function (error) {
            alert(error)
          })
        } else {
          axios.patch(action)
          .then((response) => {
            this.added = true
            if (this.addedTimeout) { clearTimeout(this.addedTimeout) }
            this.addedTimeout = setTimeout(() => {
              this.added = false
            }, 1000)
            relatedProductsVmState.wishlist = response.data.wishlist
          }).catch(function (error) {
            alert(error)
          })
        }
      },
      visit: function () {
        window.location = this.url
      }
    },
    computed: {
      localizedFirstFeature: function () {
        let locale = productsVmState.locale
        let localeFeatures = this.product.features.filter(feature => {
          return feature.locale === null || feature.locale == locale
        })
        if (localeFeatures.length) {
          return localeFeatures[0]['description']
        } else {
          return null
        }
      },
      buttonClass: function () {
        if (this.added) {
          return '-added'
        } else if (this.inWishlist) {
          return '-remove'
        } else {
          return ''
        }
      },
      buttonText: function () {
        if (this.added) {
          return 'Added'
        } else if (this.inWishlist) {
          return 'Remove'
        } else {
          return 'My Catalogue'
        }
      },
      inWishlist: function () {
        return relatedProductsVmState.wishlist.products.findIndex(p => p.id == this.product.id) !== -1
      },
      unitCm: function () {
        return relatedProductsVmState.unit === 'cms'
      },
      unitInches: function () {
        return relatedProductsVmState.unit === 'inches'
      },
      url: function () {
        return `/catalogue/${this.product.group}`
      }
    },
    template: `<div @click="visit" class="bc-products-list__product bc-product-card">
      <div class="bc-product-card__image">
        <img class="lazyload" v-bind:ix-src="product.image" sizes="(min-width: 1024px) 25vw, (min-width: 780px) 50vw, 100vw" alt="" width="700" height="495">
      </div>
      <div class="bc-product-card__info">
        <span class="bc-product-card__group">{{ product.group }}</span>
        <!--<span v-if="localizedFirstFeature" class="bc-product-card__feature">{{ localizedFirstFeature }}</span>-->
        <span v-if="product.description" class="bc-product-card__feature">{{ product.description }}</span>
        <span v-if="unitCm" class="bc-product-card__sizes" data-sizes="cms">{{ product.sizes_cm }}</span>
        <span v-if="unitInches" class="bc-product-card__sizes" data-sizes="inches">{{ product.sizes_inch }}</span>
      </div>
      <button class="bc-product-card__button" :class="buttonClass" v-on:click.stop.prevent="toggleInWishlist" type="button">
        <svg v-if="added" width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg"><path d="M8.493 15.442c3.838 0 6.95-3.11 6.95-6.95 0-3.837-3.112-6.948-6.95-6.948s-6.95 3.11-6.95 6.95c0 3.837 3.112 6.948 6.95 6.948zm0 1.544C3.803 16.986 0 13.184 0 8.493 0 3.803 3.803 0 8.493 0s8.493 3.803 8.493 8.493-3.802 8.493-8.493 8.493zm-2.26-6.392L4.96 9.337c-.222-.22-.593-.22-.82.004-.23.23-.23.59-.004.815l1.657 1.637.002.003.025.025c.227.225.597.225.827-.003l5.355-5.292c.23-.226.232-.59.003-.818-.228-.225-.598-.224-.828.003l-4.944 4.887z" fill="#72BA3A"/></svg>
        <svg v-else-if="inWishlist" width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg"><path d="M8.46 15.383c3.824 0 6.923-3.1 6.923-6.922 0-3.82-3.1-6.92-6.922-6.92-3.82 0-6.92 3.1-6.92 6.924 0 3.824 3.1 6.923 6.924 6.923zm0 1.538C3.79 16.92 0 13.137 0 8.46 0 3.79 3.788 0 8.46 0c4.673 0 8.46 3.788 8.46 8.46 0 4.673-3.787 8.46-8.46 8.46zM6.15 7.696c-.417 0-.766.344-.766.77 0 .426.343.768.767.768h4.62c.42 0 .77-.344.77-.77 0-.426-.343-.767-.767-.767H6.15z" fill="#D65466"/></svg>
        <svg v-else width="17" height="17" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg"><path d="M8.493 15.442c3.838 0 6.95-3.11 6.95-6.95 0-3.837-3.112-6.948-6.95-6.948s-6.95 3.11-6.95 6.95c0 3.837 3.112 6.948 6.95 6.948zm0 1.544C3.803 16.986 0 13.184 0 8.493 0 3.803 3.803 0 8.493 0s8.493 3.803 8.493 8.493-3.802 8.493-8.493 8.493zm.772-9.265V6.178c0-.42-.345-.77-.772-.77-.43 0-.772.344-.772.77V7.72H6.178c-.42 0-.77.347-.77.773 0 .43.344.772.77.772H7.72v1.547c0 .42.347.77.773.77.43 0 .772-.345.772-.77V9.265h1.547c.42 0 .77-.345.77-.772 0-.43-.345-.772-.77-.772H9.265z" fill="#273455"/></svg>
        {{ buttonText }}
      </button>
    </div>`
  })

  window.relatedProductsVm = new Vue({
    el: '#vue-related-products',
    data: relatedProductsVmState
  })
})
